// Context to load

import { useParameteres } from 'contexts/ParameteresContext';
import {
  useEffect, useReducer, useState, useMemo,
} from 'react';
import {
  Select, Row, Col, Space, DatePicker, Typography, Alert,
} from 'antd';
import { useLocalization } from 'contexts/LocalizationContext';
import { useTransformationTypes } from 'contexts/TransformationTypesContext';
import { useAuth } from 'contexts/AuthContext';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useProductTypes } from 'contexts/ProductTypesContext';
import NewStraw from './forms/NewStraw';
import FirstTransformation from './forms/FirstTransformation';
import SecondTransformation from './forms/SecondTransformation';
import CardingForm from './forms/CardingForm';
import 'assets/styles/transitions.css';
import { getValue } from './formatters/ObjectFormatter';
import CorrectionForm from './forms/CorrectionForm';
import CloseMonth from './forms/CloseMonth';
import { checkAdmin } from './RequireAdmin';

const { Option } = Select;
const { Title, Text, Paragraph } = Typography;

/**
 * The transformation forms wrapper
 */
function reducer(state, action) {
  switch (action.type) {
    case 'HARVEST':
      return {
        type: action.type, title: action.title, transType: action.transType, OperationForm: NewStraw,
      };
    case 'FIRST':
      return {
        type: action.type, title: action.title, transType: action.transType, OperationForm: FirstTransformation,
      };
    case 'SECOND':
      return {
        type: action.type, title: action.title, transType: action.transType, OperationForm: SecondTransformation,
      };
    case 'CORRECTION_STOCK_PRODUCTION':
      return {
        type: action.type, title: action.title, transType: action.transType, OperationForm: CorrectionForm,
      };
    case 'CORRECTION_STOCK':
      return {
        type: action.type, title: action.title, transType: action.transType, OperationForm: CorrectionForm,
      };
    case 'CLOSE':
      return {
        type: action.type, title: action.title, transType: action.transType, OperationForm: CloseMonth,
      };
    default:
      return { type: 'non', translationKey: 'transformation_newDeclaration_title' };
  }
}
function TransformationForm(props) {
  const {
    dataSource, setShowErrorMessege, handleNewDataHidden, onStateChange, balances,
  } = props;
  // Contexts
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`;
  const parameters = useParameteres();
  const productTypes = useProductTypes();

  const transformationTypes = useTransformationTypes();

  const { user } = useAuth();
  const { sId } = useParams();
  // States
  const [date, setDate] = useState();
  const [dateObject, setDateObject] = useState(moment());
  const [accounts, setAccounts] = useState({ values: null, isLoading: true });
  const [selectedOption, setSelectedOption] = useState('non');
  const [qualities, setQualities] = useState({ values: null, isLoading: true });
  const [lastOperationDate, setLastOperationDate] = useState({ values: null, isLoading: true });
  const [showInformationMessage, setshowInformationMessage] = useState({ visible: false, type: '', msg: '' });
  const [allowedMonthParam, setAllowedMonthParam] = useState(1);
  const [lastDateData, setLastDateData] = useState(null);
  const [monthAlreadyClose, setMonthAlreadyClose] = useState(false);
  const [lastMonthBefore, setLastMonthBefore] = useState(false);
  const [closeSeveralMonths, setCloseSeveralMonths] = useState(false);
  const [inputType, dispatch] = useReducer(reducer, {
    type: 'non',
    locObject,
    translationKey: 'transformation_newDeclaration_title',
    transType: 'non',
  });
  // Memorizing products ids
  // provide the transformation forms with products ids ..
  const productsIds = useMemo(() => {
    if (productTypes.values.length > 0) {
      const strawId = productTypes.values.find((p) => p.enumType === 'FLAX_STRAW').id ?? false;
      const clfId = productTypes.values.find((p) => p.enumType === 'CLASSIFIED_LONG_FIBER').id ?? false;
      const dlfId = productTypes.values.find((p) => p.enumType === 'DECLASSIFIED_LONG_FIBER').id ?? false;
      const rsfId = productTypes.values.find((p) => p.enumType === 'RAW_SHORT_FIBER').id ?? false;
      const sssfId = productTypes.values.find((p) => p.enumType === 'SEMI_SCUTCHED_SHORT_FIBER').id ?? false;
      const ssfId = productTypes.values.find((p) => p.enumType === 'SCUTCHED_SHORT_FIBER').id ?? false;
      const lin = productTypes.values.find((p) => p.enumType === 'CARDED_FLAX').id ?? false;
      return {
        strawId, clfId, dlfId, rsfId, ssfId, sssfId, lin,
      };
    }
    return {};
  }, [productTypes]);
  function handleDataReceived(data) {
    setshowInformationMessage({ visible: data ?? false });
  }
  useEffect(() => {
    if (parameters.values.data) {
      // transformation allowed month
      const m = Number(parameters.values.data.find((param) => param.name === 'transformation.month').value);
      setAllowedMonthParam(m);
    }
  }, [parameters.values]);
  function getAccounts() {
    const response = dataSource.getAllAccounts();
    response.then(({ data }) => {
      setAccounts({ values: data, isLoading: false });
    });
  }
  function getQualities() {
    const response = dataSource.getQualities();
    response.then(({ data }) => {
      setQualities({ values: data, isLoading: false });
    });
  }

  function getLastDateTypeOperation(type) {
    const response = dataSource.getLastDateTypeOperation(dataSource.company, type);
    response.then(({ data }) => {
      setLastOperationDate({ values: data.lastDate, isLoading: false });
    });
  }

  function getLastDateCloseMonth(type) {
    const response = dataSource.getLastDateCloseMonth(dataSource.company, type);
    response.then(({ data }) => {
      setLastDateData(data);
    });
  }

  function checkCloseMonthDate() {
    if (date) {
      const currentDate = date.dt.format('YYYY-MM');
      if (lastDateData.length !== 0) {
        setCloseSeveralMonths(false);
        const dtLastCloseDate = moment(new Date(lastDateData[0].date), 'YYYY-MM');
        if (currentDate <= dtLastCloseDate.format('YYYY-MM')) {
          setshowInformationMessage({ visible: true, type: 'error', msg: locObject.translate('month_already_close') });
          setMonthAlreadyClose(true);
        } else if (currentDate !== dtLastCloseDate.add(1, 'month').format('YYYY-MM')) {
          setshowInformationMessage({ visible: true, type: 'error', msg: locObject.translate('close_last_month_before') });
          setLastMonthBefore(true);
        } else {
          setshowInformationMessage({ visible: false });
        }
      } else if (lastDateData.length === 0) {
        setshowInformationMessage({ visible: true, type: 'warning', msg: locObject.translate('transformation_closeSeveralMonth_warning') });
        setCloseSeveralMonths(true);
      }
    }
  }

  function checkAllowedDates(current) {
    // if ok return false (dont disable the date)
    const todayDate = moment();
    if (checkAdmin(user) === true) {
      return false;
    }
    if (current) {
      // allowed months range

      const allowedDates = moment().subtract(allowedMonthParam, 'month').startOf('month');
      if (!current.startOf('month').isBetween(allowedDates, todayDate)) {
        return true;
      }
      return false;
    }
    return false;
  }
  useEffect(() => {
    getAccounts();
    getLastDateTypeOperation('TRANSFORMATION');
    getLastDateCloseMonth('CLOSE');
    // getQualities();
    if (inputType.type === 'non') {
      setDate(0);
      setDateObject('');
      setSelectedOption();
      handleNewDataHidden(true);
    } else if (inputType.type === 'CLOSE') {
      getLastDateTypeOperation('CLOSE');
      checkCloseMonthDate();
    } else {
      handleNewDataHidden(false);
    }
    if (date && (lastDateData !== null && lastDateData.length !== 0)) {
      const currentDate = date.dt.format('YYYY-MM');
      const dtLastCloseDate = moment(new Date(lastDateData[0].date), 'YYYY-MM');
      if (currentDate <= dtLastCloseDate.format('YYYY-MM')) {
        setMonthAlreadyClose(true);
      }
    }
    return () => {
      setShowErrorMessege({ visible: false });
    };
  }, [inputType.type]);

  useEffect(() => {
    if (lastOperationDate.values) {
      setDateObject(moment(lastOperationDate.values));
    }
  }, [lastOperationDate.values]);

  function handleChangeDate(dt, dateString) {
    setMonthAlreadyClose(false);
    setDate({ dt, dateString });
    setDateObject(dt);
    if (inputType.type === 'CLOSE') {
      checkCloseMonthDate();
    }
  }

  const handleChildSubmit = (data) => {
    setDateObject(data);
  };
  function handleOperationType(value, element) {
    // eslint-disable-next-line no-use-before-define
    handleTypeChange(element.id);
    setSelectedOption(element.children);
    dispatch({ type: element.id, title: element.children, transType: value });
  }

  const handleTypeChange = (typeState) => {
    let visible = false;
    let message = '';
    if (typeState === 'SECOND') {
      visible = true;
      message = locObject.translate('information_type_second_transformation');
    } else if (typeState === 'CORRECTION_STOCK') {
      visible = true;
      message = locObject.translate('information_type_other_transformation');
    }

    setshowInformationMessage({ visible, type: '', msg: message });
  };

  return (
    <div className="noStyle">
      <Space className="d-100" direction="vertical" size="middle">
        <Title level={5}>
          {inputType.title ?? locObject.translate(inputType.translationKey)}
        </Title>
        { showInformationMessage.visible === true && (
          <Alert
            style={{ marginTop: 10 }}
            message={showInformationMessage.msg}
            type={showInformationMessage.type !== '' ? showInformationMessage.type : 'info'}
            closable
            onClose={() => setshowInformationMessage({ visible: false })}
          />
        )}
        <Row gutter={[10, 5]}>
          <Col sm={12} md={5} lg={3}>
            <Paragraph strong>
              <Text type="danger">* </Text>
              {locObject.translate('date')}
            </Paragraph>
            <DatePicker.MonthPicker
              disabled={parameters.isLoading === true}
              format="MMM YYYY"
              allowClear
              defaultValue={null}
              disabledDate={checkAllowedDates}
              onChange={handleChangeDate}
              showToday={false}
            />
          </Col>
          <Col sm={12} md={8} lg={5}>
            <Paragraph strong>
              <Text type="danger">* </Text>
              {locObject.translate('operationType')}
            </Paragraph>
            <Select
              loading={transformationTypes.isLoading === true}
              disabled={transformationTypes.isLoading === true}
              value={selectedOption}
              onChange={handleOperationType}
              placeholder={locObject.translate('transformation_newDeclaration_operation_ph')}
              dropdownMatchSelectWidth={false}
              className="d-100"
              defaultActiveFirstOption={false}
            >
              {transformationTypes
                .values.filter((ty) => ty.enumType !== 'SELL' && (checkAdmin(user) !== true ? ty.enumType !== 'CORRECTION_STOCK_PRODUCTION' : true) && ty.enumType !== 'CARDING').map((type) => (
                  <Option
                    id={type.enumType}
                    key={type.id}
                    value={type.id}
                  >
                    {getValue(type, lang)}
                  </Option>
                ))}
            </Select>
          </Col>
        </Row>
        {inputType.OperationForm && (
        <inputType.OperationForm
          date={dateObject}
          dateClose={date}
          accounts={accounts}
          balances={balances}
          // qualities={qualities}
          type={inputType.transType}
          company={sId ?? user.company}
          dispatch={dispatch}
          onDataReceived={handleDataReceived}
          onSubmit={handleChildSubmit}
          productsIds={productsIds}
          monthAlreadyClose={monthAlreadyClose}
          lastMonthBefore={lastMonthBefore}
          closeSeveralMonths={closeSeveralMonths}
          {...props}
        />
        )}
      </Space>
    </div>
  );
}

export default TransformationForm;
