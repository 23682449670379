/* eslint-disable radix */
import StatsTableNumberFormatter from 'components/formatters/StatsTableNumberFormatter';
import { useEffect, useState, useMemo } from 'react';
import {
  Button,
  Card, Col, Radio, Row, Skeleton, Space, Typography, Select,
} from 'antd';
import HomeStatsDataTable from 'components/HomeStatsDataTable';
import WebServices from 'services/webServices';
import api from 'services/api';
import { useParams } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { useParameteres } from 'contexts/ParameteresContext';
import moment from 'moment';
import { useNotificationCenter } from 'contexts/NotificationCenter';
import { useLocalization } from 'contexts/LocalizationContext';
import { DownloadOutlined } from '@ant-design/icons';
import StatsFilterDropDown from 'components/StatsFilterDropDown';
import '../../../assets/styles/home.css';
import HomeRegionStatsDataTable from 'components/HomeRegionStatsDataTable';

const firstMetaData = (translator) => [
  {
    title: translator.translate('stats_culture'),
    key: '0:total_culture',
    unit: 'ha',
    formatter: StatsTableNumberFormatter,
    formatOptions: { withDecimals: true },
  },
  {
    title: translator.translate('stats_seed'),
    key: '0:total_semen',
    unit: 'ha',
    formatter: StatsTableNumberFormatter,
    formatOptions: { withDecimals: true },
  },
];

const tableColumns = (translator, key, period) => [
  translator.translate(key), ' ', translator.translate('home_stats_table_total'), `R${period[0]}`, `R${period[1]}`, `R${period[2]}`, `R${period[3]}`, `R${period[4]}`, translator.translate('home_stats_surface_table_prior'),
];
const federations = [
  { shortName: 'FESTAL', value: 999997 },
  { shortName: 'USRTL', value: 999996 },
  { shortName: 'ABV', value: 999995 },
  { shortName: 'V&H', value: 999994 },
];

const optionsOrganicWinter = (translator) => [
  {
    value: 0,
    label: translator.translate('curltur_precision'),
  },
  {
    value: 4,
    label: translator.translate('withoutOrganic_withoutWinter'),
  },
  {
    value: 1,
    label: translator.translate('organic_flax'),
  },
  {
    value: 2,
    label: translator.translate('winter_flax'),
  },
  {
    value: 3,
    label: translator.translate('organic_winter'),
  },
];

const { Title, Text } = Typography;

export default function SurfaceStats() {
  const { user } = useAuth();
  const { sId } = useParams();
  const loc = useLocalization().locObject;
  const parameters = useParameteres();
  const services = useMemo(() => new WebServices({}, api, true, 'home', sId ?? user.company), []);
  const [period, setPeriod] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [generalSurfaceStatsData, setGeneralSurfaceStatsData] = useState(null);
  const [regionStatsData, setRegionStatsData] = useState(null);
  const notify = useNotificationCenter()();
  const [surfaceTypeChoices, setSurfaceTypeChoices] = useState(1);
  const [organicWinter, setOrganicWinter] = useState(0);
  const [companyId, setCompanyId] = useState(999999);
  const [type, setType] = useState(9);
  const [dates, setDates] = useState({ start: undefined, end: undefined });
  const multiScutchingUser = !user.roles.includes('ROLE_FED_ADMIN');

  const dateRange = {
    start: dates.start ? moment(dates.start).format('DD/MM/YYYY') : moment(dates.end).startOf('month').format('DD/MM/YYYY'),
    end: dates.end ? moment(dates.end).endOf('month').format('DD/MM/YYYY') : moment(dates.start).endOf('month').format('DD/MM/YYYY'),
  };

  function fetchData(cId) {
    setRegionStatsData(null);
    services.getSurfaceStatsHomePageDetails(cId).then(({ data }) => {
      setGeneralSurfaceStatsData(data.general_stats);
      setRegionStatsData(data.region_stats);
    });
  }

  useEffect(() => {
    if (parameters.values.data) {
      const date = parameters.values.data.find((param) => param.name === 'statistics.date').value;
      const y = parameters.values.data.find((param) => param.name === 'harvest.year').value;

      // generate period
      if (multiScutchingUser) {
        setDates({ start: null, end: `${date}-01` });
      } else {
        setDates({ start: `${date}-01`, end: null });
      }

      const generatedList = [0, 1, 2, 3, 4].map((item) => parseInt(y) - item);
      setPeriod(generatedList);
    }
  }, [parameters]);

  function typeChoice(surfaceType) {
    if (surfaceType === 1 && organicWinter === 4) {
      setType(1);
    } else if (surfaceType === 1 && organicWinter === 1) {
      setType(2);
    } else if (surfaceType === 1 && organicWinter === 2) {
      setType(3);
    } else if (surfaceType === 1 && organicWinter === 3) {
      setType(4);
    } else if (surfaceType === 2 && organicWinter === 4) {
      setType(5);
    } else if (surfaceType === 2 && organicWinter === 1) {
      setType(6);
    } else if (surfaceType === 2 && organicWinter === 2) {
      setType(7);
    } else if (surfaceType === 2 && organicWinter === 3) {
      setType(8);
    } else if (surfaceType === 2 && organicWinter === 0) {
      setType(10);
    } else {
      setType(9);
    }
  }

  function handleSurfaceTypeChoices(e) {
    setSurfaceTypeChoices(e.target.value);
  }

  function handleOrganicWinterValue(e) {
    setOrganicWinter(e);
  }

  function handleCompanyChoices(value) {
    fetchData(value);
    setCompanyId(value);
  }

  useEffect(() => {
    fetchData(companyId);

    async function fetchCurrentUser() {
      const result = await api.get(`/users/${user.id}`);
      setCurrentUser(result.data);
    }
    fetchCurrentUser();
  }, [user]);

  useEffect(() => {
    typeChoice(surfaceTypeChoices);
  }, [surfaceTypeChoices, organicWinter]);

  useEffect(() => {
    fetchData(companyId);
  }, [type]);

  function exportStatsPage() {
    services.exportSurfacesStatsPage(companyId, type).then((response) => {
      // check if the response is empty
      if (response.data.type === 'application/json') {
        notify({
          msg: loc.translate('nodata_export'), type: 'error', desc: '', duration: 10,
        });
      } else {
        // use a virtual <a> element to trigger the navigator download Event
        const hiddenElement = document.createElement('a');
        const blob = new Blob([response.data]);
        hiddenElement.href = URL.createObjectURL(blob);
        // save the file with the current date
        const date = moment().format('YYYY-MM-SS HH-MM-SS');
        hiddenElement.download = `surfaces-stats-${date}.xlsx`;
        hiddenElement.click();
      }
    });
  }

  return (
    <Card
      size="small"
      style={{ border: 'none' }}
      title={(
        <Button
          onClick={exportStatsPage}
          type="primary"
          shape="default"
          className="hp-export-btn"
        >
          <DownloadOutlined />
          {loc.translate('export')}
        </Button>
)}
      headStyle={{ backgroundColor: 'var(--light-secondary)' }}
    >
      <Space direction="vertical" className="w-100" size="small">
        <Space direction="vertical" className="w-100">
          <Row justify="center">
            <Col span={22}>
              <Card size="small" className=" tool-bar">
                <Space direction="horizontal" className="p-5 tool-bar " size="large">
                  <Text strong>{loc.translate('home_stats_toolBar_title')}</Text>
                  <StatsFilterDropDown
                    services={services}
                    user={user}
                    valueChanged={handleCompanyChoices}
                    federations={federations}
                    userFederation={currentUser.managedFederation}
                    filters={{
                      companyType: 3, companyFederation: currentUser.managedFederation?.id ?? 0, order_companyName: 'ASC', nbElems: 20,
                    }}
                    defaultValue={999999}
                    translator={loc}
                  />
                </Space>
              </Card>
              {generalSurfaceStatsData !== null ? (
                <HomeStatsDataTable
                  columns={tableColumns(loc, 'surface_home_stats_surfaces', period, dateRange)}
                  data={generalSurfaceStatsData}
                  metaData={firstMetaData(loc)}
                  period={period}
                  size={7}
                />
              ) : ('')}
            </Col>
          </Row>
        </Space>
        <Row justify="center">
          <Col span={22}>

            <Card size="small" className=" tool-bar">
              <Space direction="horizontal" className="w-100" size="large">
                <Text strong>{loc.translate('surface_home_stats_regions')}</Text>
                <Radio.Group onChange={handleSurfaceTypeChoices} defaultValue={surfaceTypeChoices}>
                  <Radio value={1}>
                    {loc.translate('home_stats_culture_table_title')}
                  </Radio>
                  <Radio value={2}>
                    {loc.translate('home_stats_seed_table_title')}
                  </Radio>
                </Radio.Group>
                <Select
                  options={optionsOrganicWinter(loc)}
                  onChange={handleOrganicWinterValue}
                  style={{ width: 200 }}
                  defaultValue={organicWinter}
                />
              </Space>
            </Card>
            {regionStatsData !== null ? (
              <HomeRegionStatsDataTable
                columns={tableColumns(loc, 'surface_home_stats_cultures', period)}
                data={regionStatsData[type]}
                period={period}
                size={7}
              />
            ) : ('')}
          </Col>
        </Row>
      </Space>
    </Card>
  );
}
