import { Col, Row } from 'antd';
import NestedDataTableRow from './renderers/NestedDataTableRow';
import '../assets/styles/homeStatsTable.css';

export default function HomeStatsDataTable(props) {
  const {
    columns, data, metaData, period, key, size,
  } = props;

  return (
    <div className="w-100">
      <Row className="w-100 stats-table-thead">
        {columns.map((thd, ind) => (ind === 0
          ? <Col className="stats-table-td" span={size === 5 ? 6 : 3} key={thd}>{thd}</Col>
          : <Col className="stats-table-td-headers" span={size === 5 ? 3 : null} style={{ width: size === 5 ? '' : '10%' }} key={thd}>{thd}</Col>))}
      </Row>
      {metaData.map((mt, indx) => (mt.isComponent === true ? (
        <Row
          className="seperator"
          key={mt.title}
        >
          <Col className="stats-table-td" span={24} key={`col ${mt.title}`}>{mt.title}</Col>
        </Row>
      ) : (
      // eslint-disable-next-line react/no-array-index-key
        <NestedDataTableRow dt={data} metaObject={mt} key={mt.title + indx} period={period} size={size} />
      )))}
    </div>
  );
}
