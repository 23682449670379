import React, { useState, useEffect } from 'react';
import api from 'services/api';
import DataTable from 'components/DataTable';
import {
  Card, Typography, Space, Alert,
} from 'antd';
import SimpleTextEditor from 'components/editors/SimpleTextEditor';
import TransformationForm from 'components/TransformationForm';
import { useAuth } from 'contexts/AuthContext';
import { useNotificationCenter } from 'contexts/NotificationCenter';
import NewDataBox from 'components/NewDataBox';
import { TransformationTypesProvider } from 'contexts/TransformationTypesContext';
import { ProductTypesProvider } from 'contexts/ProductTypesContext';
import TableAddonsBox from 'components/TableAddonsBox';
import FilterTransformation from 'components/forms/FilterTransformation';
import { ProductIntervalsProvider } from 'contexts/ProductIntervals';
import NumberFormatter from 'components/formatters/NumberFormatter';
import TransformationStatistics from 'components/TransformationStatistics';
import TransformationDeleteButton from 'components/editors/TransformationDeleteButton';
import TransformationRow from 'components/renderers/TransformationRow';
import 'assets/styles/transformation.css';
import CollapseButton from 'components/editors/CollapseButton';
import { useParams } from 'react-router-dom';
import WebServices from 'services/webServices';
import { useLocalization } from 'contexts/LocalizationContext';
import { ClientRegionsProvider } from 'contexts/ClientRegionsContext';
/// columns
const columns = (labelLanguage) => [
  {
    id: 'collapse',
    name: '',
    editor: CollapseButton,
    sortable: false,
    requiredObjectIndex: labelLanguage,
    header: () => <Space />,
  },
  {
    id: 'date',
    name: 'Date',
    translationKey: 'date',
    dependencyIndex: labelLanguage,
    editor: SimpleTextEditor,
    sortable: true,
  },
  {
    id: 'type',
    name: 'Opération',
    translationKey: 'operationType',
    dependencyIndex: labelLanguage,
    editor: SimpleTextEditor,
    sortable: true,
    style: { textAlign: 'left' },

  },
  {
    id: 'accountYear',
    name: 'Récolte',
    translationKey: 'transformation_table_harvest',
    requiredObjectIndex: labelLanguage,
    editor: SimpleTextEditor,
    sortable: true,
    style: { textAlign: 'left' },
  },
  {
    id: ['inQuan', 'surface'],
    name: 'Mouvement Paille',
    translationKey: 'transformation_table_straw_mov',
    translationKeys: ['transformation_table_quantity', 'surfaces_newDeclaration_culturingField_ph'],
    requiredObjectIndex: labelLanguage,
    headerStyle: 'header-mid',
    editor: SimpleTextEditor,
    sortable: false,
    groupColumn: 2,
    groupWithDecimal: [false, true],
    groupFormatter: [NumberFormatter, NumberFormatter],
    style: { textAlign: 'center' },
    groupStyle: [{ textAlign: 'right' }, { textAlign: 'right' }],

  },
  {
    id: ['outStock', 'outQuan'],
    headerStyle: 'header-mid',
    name: 'Mouvement Produits finis',
    translationKey: 'transformation_table_finalProducts_mov',
    translationKeys: ['transformation_table_outStock', 'transformation_table_quantity'],
    requiredObjectIndex: labelLanguage,
    editor: SimpleTextEditor,
    withDecimals: false,
    sortable: false,
    groupWithDecimal: [false, false],
    groupColumn: 2,
    style: { textAlign: 'center' },
    // formatters array
    groupFormatter: [(_, col, val) => val, NumberFormatter],
    // style array
    groupStyle: [{ textAlign: 'left' }, { textAlign: 'right' }],
  },
  {
    id: 'delete',
    name: '',
    editor: TransformationDeleteButton,
    sortable: false,
    header: () => <div className="empty-space" />,
  },
];
const { Title } = Typography;
const crud = {
  get: '/transformation/',
  update: '/transformation',
  delete: '/transformation',
  create: '/transformation/',
};
// export function

function Transformation() {
  // Contexts

  const { sId } = useParams();

  const { user } = useAuth();
  const notify = useNotificationCenter()();
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`;
  const dataSource = new WebServices(crud, api, true, 'transformation', sId ?? user.company);
  const [showForm, setShowForm] = useState(false);
  const [showErrorMessege, setShowErrorMessege] = useState({ visible: false, msg: '' });
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState({ total: 0, perPage: 0 });
  const [stocks, setStocks] = useState(null);
  const [stocksDetails, setstocksDetails] = useState(null);
  const [sorts, setSorts] = useState(null);

  // forms controllers

  const [filterFormHidden, setFilterFormHidden] = useState({
    isFormHidden: true,
    isButtonHidden: false,
  });
  const [newDataFormHidden, setNewDataFormHidden] = useState({
    isFormHidden: false,
    isButtonHidden: true,
  });

  // table functions
  function fetchData(page, sort, filter) {
    const response = dataSource.getTransformation(page, sort, filter);
    response.then((d) => {
      setData(d);
      setPagination({ total: dataSource.totalResults, perPage: dataSource.perPage });
    });
  }
  function updateStatistics() {
    const response = dataSource.getAllBalances();
    const responseDetails = dataSource.getBalancesDetails();
    response.then((resp) => {
      setStocks(resp.data);
    });
    responseDetails.then((resp) => {
      setstocksDetails(resp.data);
    });
  }
  useEffect(() => {
    updateStatistics();
    fetchData();
    return () => {
      setNewDataFormHidden({ isFormHidden: true, isButtonHidden: true });
    };
  }, []);

  function updateData(sort, filter) {
    updateStatistics();
    fetchData(1, sort, filter);
  }
  function handleDelete(rowId, company) {
    const response = dataSource.delete(rowId, company);
    response.then((res) => {
      if (res === true) {
        updateData(null, null);
        return notify({
          msg: locObject.translate('suppression_success_msg'),
          type: 'success',
          desc: '',
        });
      }
      return setShowErrorMessege({ msg: locObject.translate(res) ?? res, visible: true });
    });
  }
  function sortTable(sort) {
    updateData(sort);
  }
  function handleSetFilteredData(filterValues) {
    setData(undefined);
    updateData(null, filterValues);
  }

  function handleFilterFormHidden(hidden) {
    setFilterFormHidden({ isFormHidden: hidden, isButtonHidden: !hidden });
    if (hidden === false) {
      setNewDataFormHidden({ isFormHidden: true, isButtonHidden: true });
    } else {
      setNewDataFormHidden({ isFormHidden: false, isButtonHidden: true });
    }
  }
  function handleNewDataHidden(hidden) {
    setNewDataFormHidden({ isFormHidden: false, isButtonHidden: true });
    if (hidden === false) {
      setFilterFormHidden({ isFormHidden: true, isButtonHidden: true });
    } else {
      setFilterFormHidden({ isFormHidden: true, isButtonHidden: false });
    }
  }

  function transformationRow(row, properties) {
    return <TransformationRow key={row.id} row={row} {...properties} />;
  }

  const GroupsRow = (
    <tr className="master-row-group">
      <td className="master-td-group" colSpan={4} />
      <td className="master-td-group tx-r">{locObject.translate('transformation_table_weight')}</td>
      <td className="master-td-group tx-r pr-10">{locObject.translate('transformation_table_surface')}</td>
      <td className="master-td-group">{locObject.translate('parameters_general_validation_table_type')}</td>
      <td className="master-td-group tx-r">{locObject.translate('transformation_table_quantity')}</td>
      <td />
    </tr>
  );

  return (
    <Card className="d-100 h-100">
      <Space direction="vertical" size={1} className="d-100">
        <Title level={3}>{locObject.translate('transformation_title')}</Title>
        <TransformationStatistics
          stocks={stocks}
          stocksDetails={stocksDetails}
          showStraw
          valueStyle={{ fontSize: '1.2vw' }}
        />
        <ClientRegionsProvider>
          <TransformationTypesProvider>
            <ProductTypesProvider>
              <ProductIntervalsProvider>
                <NewDataBox
                  onShowForm={setShowForm}
                  newDataFormHidden={newDataFormHidden}
                  handleNewDataHidden={handleNewDataHidden}
                  showForm={showForm}
                  balances={stocksDetails}
                  dataSource={dataSource}
                  NewDataForm={TransformationForm}
                  canImport={false}
                  updateData={updateData}
                  activityName="transformation"
                />
                <TableAddonsBox
                  filterFormHidden={filterFormHidden}
                  handleFilterFormHidden={handleFilterFormHidden}
                  handleSetFilteredData={handleSetFilteredData}
                  dataSource={dataSource}
                  onShowForm={setShowForm}
                  showForm={showForm}
                  FilterForm={FilterTransformation}
                  activityName="transformation"
                  showHarvestYear={false}
                  title={locObject.translate('filters')}
                  showFilterButton
                  exportName="Transformation"
                />
                {showErrorMessege.visible === true && (
                <Alert
                  message={showErrorMessege.msg}
                  type="error"
                  closable
                  onClose={() => setShowErrorMessege({ visible: false })}
                />
                )}
                <DataTable
                  columns={columns(lang)}
                  dataSource={data}
                  fetchData={fetchData}
                  handleDelete={handleDelete}
                  sortTable={sortTable}
                  paginationPerPage={pagination.perPage}
                  paginationTotalResult={pagination.total}
                  showBulkActions={false}
                  handleCommit={() => false}
                  isEditable={false}
                  sorts={sorts}
                  isPaginatble
                  rowRenderer={transformationRow}
                  translateColumns
                  groupsRows={GroupsRow}
                />
              </ProductIntervalsProvider>
            </ProductTypesProvider>
          </TransformationTypesProvider>
        </ClientRegionsProvider>
      </Space>
    </Card>
  );
}
export default Transformation;
