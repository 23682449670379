/* eslint-disable radix */
import StatsTableNumberFormatter from 'components/formatters/StatsTableNumberFormatter';
import { useEffect, useState, useMemo } from 'react';
import {
  Button,
  Card, Col, DatePicker, Radio, Row, Skeleton, Space, Typography,
} from 'antd';
import HomeStatsDataTable from 'components/HomeStatsDataTable';
import WebServices from 'services/webServices';
import api from 'services/api';
import { useParams } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { useParameteres } from 'contexts/ParameteresContext';
import { useFederations } from 'contexts/FederationsContext';
import moment from 'moment';
import { useNotificationCenter } from 'contexts/NotificationCenter';
import { useLocalization } from 'contexts/LocalizationContext';
import { DownloadOutlined } from '@ant-design/icons';
import StatsFilterDropDown from 'components/StatsFilterDropDown';
import '../../../assets/styles/home.css';

const firstMetaData = (translator) => [
  {
    title: translator.translate('flax_straw', 2),
    isComponent: true,
  },
  {
    title: translator.translate('home_processed_straw'),
    key: '0:production',
    unit: 'kg',
    formatter: StatsTableNumberFormatter,
  },

  {
    title: '',
    key: '0:surface',
    unit: 'ha',
    formatter: StatsTableNumberFormatter,
  },
  {
    title: translator.translate('home_stats_stocks_end_table'),
    key: '1:stock',
    unit: 'kg',
    formatter: StatsTableNumberFormatter,
  },
  {
    title: '',
    key: '1:surface',
    unit: 'ha',
    formatter: StatsTableNumberFormatter,
  },
  {
    title: translator.translate('home_stats_longFibers_table_total'),
    isComponent: true,
  },
  {
    title: translator.translate('home_stats_stocks_table_production'),
    key: '10:production',
    unit: 'kg',
    formatter: StatsTableNumberFormatter,
  },
  {
    title: translator.translate('home_stats_stocks_table_sales'),
    key: '10:total_sold',
    unit: 'kg',
    formatter: StatsTableNumberFormatter,
  },
  {
    title: translator.translate('home_stats_stocks_table_prix_moyens'),
    key: '10:avg_eur_kg',
    unit: '€/kg',
    formatter: StatsTableNumberFormatter,
    formatOptions: { withDecimals: true },
  },
  {
    title: translator.translate('home_stats_stocks_table_turnover'),
    key: '10:sales_eur',
    unit: '€',
    formatter: StatsTableNumberFormatter,
    formatOptions: { withDecimals: false },
  },
  {
    title: translator.translate('home_stats_stocks_end_table'),
    key: '10:stock',
    unit: 'kg',
    formatter: StatsTableNumberFormatter,
  },
  {
    title: translator.translate('home_stats_shortFibers_table_total'),
    isComponent: true,
  },
  {
    title: translator.translate('home_stats_stocks_table_production'),
    key: '20:production',
    unit: 'kg',
    formatter: StatsTableNumberFormatter,
  },
  {
    title: translator.translate('home_stats_stocks_table_sales'),
    key: '20:total_sold',
    unit: 'kg',
    formatter: StatsTableNumberFormatter,
  },
  {
    title: translator.translate('home_stats_stocks_table_prix_moyens'),
    key: '20:avg_eur_kg',
    unit: '€/kg',
    formatter: StatsTableNumberFormatter,
    formatOptions: { withDecimals: true },
  },
  {
    title: translator.translate('home_stats_stocks_table_turnover'),
    key: '20:sales_eur',
    unit: '€',
    formatter: StatsTableNumberFormatter,
    formatOptions: { withDecimals: false },
  },
  {
    title: translator.translate('home_stats_stocks_end_table'),
    key: '20:stock',
    unit: 'kg',
    formatter: StatsTableNumberFormatter,
  },
];
const secondMetaData = (productId, translator) => [
  {
    title: translator.translate('home_stats_stocks_table_production'),
    key: `${productId}:production`,
    unit: 'kg',
    formatter: StatsTableNumberFormatter,
  },
  {
    title: translator.translate('home_stats_stocks_table_sales'),
    key: `${productId}:total_sold`,
    unit: 'kg',
    formatter: StatsTableNumberFormatter,
  },
  {
    title: translator.translate('home_stats_stocks_table_prix_moyens'),
    key: `${productId}:avg_eur_kg`,
    unit: '€/kg',
    formatter: StatsTableNumberFormatter,
    formatOptions: { withDecimals: true },
  },
  {
    title: translator.translate('home_stats_stocks_table_turnover'),
    key: `${productId}:sales_eur`,
    unit: '€',
    formatter: StatsTableNumberFormatter,
    formatOptions: { withDecimals: false },
  },
  {
    title: translator.translate('home_stats_stocks_end_table'),
    key: `${productId}:stock`,
    unit: 'kg',
    formatter: StatsTableNumberFormatter,
  },
];

const longFibers = [
  { id: 2, key: 'home_stats_longFibers_classed' },
  { id: 3, key: 'home_stats_downgraded_longFibers' },
];
const shortFibers = [
  { id: 4, key: 'home_stats_ShortRawFibers' },
  { id: 5, key: 'home_stats_halfShortCutFibers' },
  { id: 6, key: 'home_stats_shortCutFibers' },
];
const tableColumns = (translator, period, statsMonth) => [
  translator.translateWithReplace('home_stats_stocks_table_mois', { placeholder: 'xxxx', value: statsMonth.start }, { placeholder: 'yyyy', value: statsMonth.end }),
  ' ', translator.translate('home_stats_table_total'), `R${period[0]}`, `R${period[1]}`, `R${period[2]}`, translator.translate('home_stats_table_prior'),
];
const fibersTableColumns = (translator, key, period) => [
  translator.translate(key), ' ', translator.translate('home_stats_table_total'), `R${period[0]}`, `R${period[1]}`, `R${period[2]}`, translator.translate('home_stats_table_prior'),
];
const federations = [
  { shortName: 'FESTAL', value: 999997 },
  { shortName: 'USRTL', value: 999996 },
  { shortName: 'ABV', value: 999995 },
  { shortName: 'V&H', value: 999994 },
];

const { Title, Text } = Typography;

export default function StockProductionStatsAdmin() {
  const { user } = useAuth();
  const { sId } = useParams();
  const loc = useLocalization().locObject;
  const parameters = useParameteres();
  const services = useMemo(() => new WebServices({}, api, true, 'home', sId ?? user.company), []);
  const [period, setPeriod] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [statsData, setStatsData] = useState(null);
  const notify = useNotificationCenter()();
  const [fibersChoices, setFiberChoices] = useState(1);
  const [fibersMeta, setFibersMeta] = useState('long');
  const [stockFilter, setStockFilter] = useState(999999);
  const [dates, setDates] = useState({ start: undefined, end: undefined });
  const [datesVerficationStatus, setDatesVerficationStatus] = useState('');
  const [originEndDate, setOriginEndDate] = useState(null);
  const multiScutchingUser = !user.roles.includes('ROLE_FED_ADMIN');

  const dateRange = {
    start: dates.start ? moment(dates.start).format('DD/MM/YYYY') : moment(dates.end).startOf('month').format('DD/MM/YYYY'),
    end: dates.end ? moment(dates.end).endOf('month').format('DD/MM/YYYY') : moment(dates.start).endOf('month').format('DD/MM/YYYY'),
  };

  function fetchData(startDate, endDate, type) {
    services.getStatsHomePageDetails(startDate, endDate, type).then(({ data }) => setStatsData(data));
  }
  useEffect(() => {
    if (parameters.values.data) {
      const date = parameters.values.data.find((param) => param.name === 'statistics.date').value;
      const y = parameters.values.data.find((param) => param.name === 'harvest.year').value;
      if (date) {
        setOriginEndDate(date);
      }
      // generate period
      if (multiScutchingUser) {
        setDates({ start: null, end: `${date}-01` });
      } else {
        setDates({ start: `${date}-01`, end: null });
      }

      const generatedList = [0, 1, 2].map((item) => parseInt(y) - item);
      setPeriod(generatedList);
    }
  }, [parameters]);

  function handleFibersChoices(e) {
    if (e.target.value === 1) {
      setFibersMeta('long');
    } else {
      setFibersMeta('short');
    }
    setFiberChoices(e.target.value);
  }
  function handleStokcsChoices(value) {
    fetchData(dates.start, dates.end, value);
    setStockFilter(value);
  }

  useEffect(() => {
    if (dates.start) {
      fetchData(dates.start, dates.end, stockFilter);
    } else if (multiScutchingUser && dates.end) {
      fetchData(dates.start, dates.end, stockFilter);
    }

    async function fetchCurrentUser() {
      const result = await api.get(`/users/${user.id}`);
      setCurrentUser(result.data);
    }
    fetchCurrentUser();
  }, [dates, user]);

  function changeStartDate(mmt) {
    if (mmt !== null) {
      const date = mmt.startOf('month').format('YYYY-MM-DD');
      if (mmt.isAfter(dates.end)) {
        setDatesVerficationStatus('error');
      } else {
        setDates({ start: date, end: dates.end });
        setDatesVerficationStatus('');
      }
    } else {
      const date = parameters.values.data.find((param) => param.name === 'statistics.date').value;
      setDates({ start: `${date}-01`, end: null });
    }
  }
  function changeEndDate(mmt) {
    if (mmt !== null) {
      const date = mmt.startOf('month').format('YYYY-MM-DD');
      if (mmt.isBefore(dates.start)) {
        setDatesVerficationStatus('error');
      } else {
        setDates({ start: dates.start, end: date });
        setDatesVerficationStatus('');
      }
    } else {
      setDates({ start: dates.start, end: null });
      setDatesVerficationStatus('');
    }
  }

  function disabledStartDate(current) {
    const startDate = moment(dates.start);
    const endDate = moment(originEndDate).startOf('month');
    if (!current || !startDate || !endDate) {
      return false;
    }
    if (multiScutchingUser) {
      return current.valueOf() > endDate.add(1, 'M').valueOf();
    }
    return current.valueOf() > endDate.valueOf();
  }

  function disabledEndDate(current) {
    const startDate = moment(dates.start);
    const endDate = moment(originEndDate).startOf('month');
    if (!current || !startDate || !endDate) {
      return false;
    }
    if (multiScutchingUser) {
      return current.valueOf() > endDate.valueOf();
    }
    return current.valueOf() > endDate.add(1, 'M').valueOf();
  }

  function exportStatsPage() {
    let filters = [];
    if (multiScutchingUser) {
      filters = { endDate: `${dates.end}`, type: stockFilter };
      if (dates.start) {
        filters.startDate = dates.start;
      }
    } else {
      filters = { startDate: `${dates.start}`, type: stockFilter };
      if (dates.end) {
        filters.endDate = dates.end;
      }
    }

    services.exportStatsPage(filters).then((response) => {
      // check if the response is empty
      if (response.data.type === 'application/json') {
        notify({
          msg: loc.translate('nodata_export'), type: 'error', desc: '', duration: 10,
        });
      } else {
        // use a virtual <a> element to trigger the navigator download Event
        const hiddenElement = document.createElement('a');
        const blob = new Blob([response.data]);
        hiddenElement.href = URL.createObjectURL(blob);
        // save the file with the current date
        const date = moment().format('YYYY-MM-SS HH-MM-SS');
        hiddenElement.download = `stats-${date}.xlsx`;
        hiddenElement.click();
      }
    });
  }

  if (statsData != null) {
    return (
      <Card
        size="small"
        style={{ border: 'none' }}
        title={(
          <Button
            onClick={exportStatsPage}
            type="primary"
            shape="default"
            className="hp-export-btn"
          >
            <DownloadOutlined />
            {loc.translate('export')}
          </Button>
)}
        headStyle={{ backgroundColor: 'var(--light-secondary)' }}
      >
        <Space direction="vertical" className="w-100" size="small">
          <Space direction="vertical" className="w-100">
            <Row justify="center">
              <Col span={22}>
                <Card size="small" className=" tool-bar">
                  <Space direction="horizontal" className="p-5 tool-bar " size="large">
                    <Text strong>{loc.translate('home_stats_toolBar_startDate')}</Text>
                    <DatePicker picker="month" onChange={changeStartDate} defaultValue={dates.start ? moment(dates.start) : null} disabledDate={disabledStartDate} status={datesVerficationStatus} allowClear={false} />
                    <Text strong>{loc.translate('home_stats_toolBar_endDate')}</Text>
                    <DatePicker picker="month" onChange={changeEndDate} defaultValue={dates.end ? moment(dates.end) : null} status={datesVerficationStatus} disabledDate={disabledEndDate} allowClear={false} />
                    <Text strong>{loc.translate('home_stats_toolBar_title')}</Text>
                    <StatsFilterDropDown
                      services={services}
                      user={user}
                      valueChanged={handleStokcsChoices}
                      federations={federations}
                      userFederation={currentUser.managedFederation}
                      filters={{
                        companyType: 3, companyFederation: currentUser.managedFederation?.id ?? 0, order_companyName: 'ASC', nbElems: 20,
                      }}
                      defaultValue={999999}
                      translator={loc}
                    />
                  </Space>

                </Card>
                <HomeStatsDataTable
                  columns={tableColumns(loc, period, dateRange)}
                  data={statsData}
                  metaData={firstMetaData(loc)}
                  period={period}
                  size={5}
                />
              </Col>
            </Row>
          </Space>
          <Row justify="center">
            <Col span={22}>

              <Card size="small" className=" tool-bar">
                <Space direction="horizontal" className="w-100" size="large">
                  <Text strong>{loc.translate('home_stats_toolBar_fibers_title')}</Text>
                  <Radio.Group onChange={handleFibersChoices} value={fibersChoices}>
                    <Radio value={1}>
                      {loc.translate('home_stats_longFibers_table_title')}
                    </Radio>
                    <Radio value={2}>
                      {loc.translate('home_stats_shortFibers_table_title')}
                    </Radio>
                  </Radio.Group>
                </Space>
              </Card>

              {fibersMeta === 'long'
          && longFibers.map(({ id, key }) => (
            <HomeStatsDataTable
              columns={fibersTableColumns(loc, key, period)}
              data={statsData}
              metaData={secondMetaData(id, loc)}
              period={period}
              key={`long ${id}${key}`}
              size={5}
            />
          ))}
              {fibersMeta === 'short'
          && shortFibers.map(({ id, key }) => (
            <HomeStatsDataTable
              columns={fibersTableColumns(loc, key, period)}
              data={statsData}
              metaData={secondMetaData(id, loc)}
              period={period}
              key={`short ${id}${key}`}
              size={5}
            />
          ))}
            </Col>
          </Row>
        </Space>
      </Card>
    );
  }
  return <Skeleton />;
}
