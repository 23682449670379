import {
  Link,
  Outlet,
  useLocation,
} from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import {
  Layout,
  Breadcrumb,
  Image,
  Space,
  Menu,
} from 'antd';
import { configPagesAdmin, configPagesUser } from 'config/pages';

import { CountriesProvider } from 'contexts/CountriesContext';
import { RegionsProvider } from 'contexts/RegionsContext';
import { useEffect, useState } from 'react';
import { ParameteresProvider } from 'contexts/ParameteresContext';
import logo from 'assets/images/logo-light.png';

import NavMenu from 'components/NavMenu';
import { HomeFilled } from '@ant-design/icons';
import { hideIfNotAdmin } from 'services/shared';
import { useLocalization } from 'contexts/LocalizationContext';
import { FederationsProvider } from 'contexts/FederationsContext';
import { CompanyInfoProvider } from 'contexts/CompanyInfoContext';

const { Header, Content, Footer } = Layout;

function MainLayout() {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const loc = useLocalization();
  const { locObject, updateFunc } = loc;
  const userIsAdmin = user.roles.includes('ROLE_MULTISCUTCHING_ADMIN');
  const navLinks = userIsAdmin ? configPagesAdmin : configPagesUser;
  const [navHistory, setNavHistory] = useState([['home', navLinks[0].link]]);
  const [currentLocation, setCurrentLocation] = useState({ title: 'home' });
  const year = new Date().getFullYear();

  function updateBreadCrumbs(location) {
    let hist = navHistory;
    let pg = navLinks.find((lin) => lin.link === location);
    if (userIsAdmin && !pg) {
      // Scutching management links
      const title = navLinks.find((link) => location.match(link.link) && link.link !== '/admin' && link.link !== '/admin/manage-scutching')?.title ?? '';
      pg = { title, link: pathname };
      setCurrentLocation(pg);
    } else {
      setCurrentLocation(pg);
    }
    const alreadyIn = navHistory.find(([k, v]) => v === location);
    if (pg) {
      if (alreadyIn === undefined) {
        hist.push([pg.title, pg.link]);
      } else {
        const index = navHistory.findIndex(([k, v]) => k === pg.title);
        const reordered = hist.slice(0, index + 1);
        hist = [];
        reordered.forEach((arr) => {
          hist.push(arr);
        });
      }
    }
    setNavHistory(hist);
  }
  useEffect(() => {
    updateBreadCrumbs(pathname);
  }, [pathname]);
  document.title = `Flaxdata | ${locObject.translate(currentLocation?.title)}`;
  return (
    <Layout className="h-min-vh-100">
      <Header>
        <div className="logo">
          <Image src={logo} preview={false} style={{ height: '60px', width: '65px', marginTop: '-5.5px' }} />
        </div>
        <NavMenu navLinks={navLinks} currentLocation={currentLocation} updateLang={updateFunc} />
      </Header>
      <Content style={{ paddingLeft: '50px', paddingRight: '50px' }}>
        <Layout className="title-container">
          <Breadcrumb className="breadcrumb-container">
            {navHistory.map(([title, link]) => (
              <Breadcrumb.Item key={link} className={currentLocation?.link === link ? 'bread-active' : 'breadcrumb'}>
                <Link to={link}>
                  <Space direction="horizontal" size="small" style={{ position: 'relative', zIndex: '10' }}>
                    {link === '/' || link === '/admin' ? <HomeFilled /> : ''}
                    {locObject.translate(title)}
                  </Space>
                </Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </Layout>
        {user ? (
          <ParameteresProvider>
            <CountriesProvider>
              <RegionsProvider>
                <FederationsProvider>
                  <Outlet />
                </FederationsProvider>
              </RegionsProvider>
            </CountriesProvider>
          </ParameteresProvider>
        ) : (
          <Outlet />
        )}
      </Content>
      <Footer className="nav-footer nav-menu">
        <Menu
          className="justify-center-left"
          mode="horizontal"
          theme="dark"
        >
          <Menu.Item key={1}>
            CELC ©
            {year}
          </Menu.Item>
          <Menu.Item key={2}>
            <Link to={navLinks.find((l) => l.title === 'breadcrumb_privacy_policy').link}>
              {locObject.translate('footer_legal_text')}
            </Link>
          </Menu.Item>
          <Menu.Item key={3}>
            <Link to={navLinks.find((l) => l.title === 'breadcrumb_reglement').link}>
              {locObject.translate('footer_reglement_text')}
            </Link>
          </Menu.Item>
        </Menu>
      </Footer>
    </Layout>
  );
}

export default MainLayout;
