import { CloseCircleFilled, SaveOutlined } from '@ant-design/icons';
import {
  Form, Row, Col, Button, Space, Alert, Checkbox, Popconfirm,
} from 'antd';
import { useLocalization } from 'contexts/LocalizationContext';
import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment';

function CloseMonth(props) {
  const [form] = Form.useForm();
  const {
    date, type, company, onSubmitData, dataSource, dispatch, onSubmit, monthAlreadyClose, lastMonthBefore, dateClose, closeSeveralMonths,
  } = props;

  // Contexts
  const loc = useLocalization();
  const { locObject } = loc;
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, msg: '', type: 'error' });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [checked, setChecked] = useState(false);

  function onChangeCheckbox(value) {
    setChecked(value.target.checked);
  }

  function onValidate() {
    if (checked) {
      setIsSubmitted(false);
    }
    setIsSubmitted(true);
    if (date === 0) {
      setIsSubmitted(false);
      return setAlert({ show: true, msg: locObject.translate('transformation_date_mandetory_err'), type: 'error' });
    }

    const newCloseObject = {
      date: moment(new Date(dateClose.dt)).format('YYYY-MM-DD'),
      type,
      company,
    };

    const response = onSubmitData(newCloseObject);
    response
      .then((res) => {
        setAlert({ show: false });
        setIsSubmitted(false);
        dispatch({ type: 'non' });
        onSubmit(date);
      })
      .catch((err) => {
        setIsSubmitted(false);
      });
    setIsLoading(false);
    return true;
  }

  return (
    <CSSTransition in timeout={500} classNames="form" appear unmountOnExit>
      <Space size="middle" direction="vertical" className="d-100">
        {alert.show && <Alert type={alert.type} message={alert.msg} />}
        <Form layout="vertical" onFinish={onValidate} name="0" form={form} labelWrap>
          <Checkbox onChange={onChangeCheckbox}>{locObject.translate('check_close_msg')}</Checkbox>
          <Row justify="end" gutter={[10, 20]}>
            <Col>
              <Button
                danger
                onClick={() => dispatch({ type: 'non' })}
                icon={<CloseCircleFilled />}
                htmlType="submit"
              >
                {locObject.translate('cancel')}
              </Button>
            </Col>
            <Col>
              {closeSeveralMonths ? (
                <Popconfirm onConfirm={onValidate} title={loc.locObject.translate('transformation_closeSeveralMonth_warning')}>
                  <Button
                    icon={<SaveOutlined />}
                    htmlType="submit"
                    loading={isSubmitted}
                    disabled={!checked || isSubmitted || monthAlreadyClose || lastMonthBefore}
                  >
                    {locObject.translate('close_month')}
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  icon={<SaveOutlined />}
                  htmlType="submit"
                  loading={isSubmitted}
                  disabled={!checked || isSubmitted || monthAlreadyClose}
                >
                  {locObject.translate('close_month')}
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Space>
    </CSSTransition>
  );
}

export default CloseMonth;
