import { useAuth } from 'contexts/AuthContext';
import { useLocalization } from 'contexts/LocalizationContext';
import moment from 'moment';
import {
  Button, Card, Row, Col, Typography, Form,
} from 'antd';
import { useNotificationCenter } from 'contexts/NotificationCenter';
import { useState, useEffect } from 'react';
import {
  FilterFilled, DownloadOutlined, CloseCircleFilled,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import styles from '../assets/styles/TableAddonsBox.module.css';
import 'assets/styles/transitions.css';

const { Title } = Typography;

function TableAddonsBox(props) {
  // context
  const [form] = Form.useForm();
  const { user } = useAuth();
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const lang = `label${currentLang.toUpperCase()}`;
  const notify = useNotificationCenter()();
  // Props
  const {
    handleSetFilteredData, activityName, dataSource, title,
    showExportButton, showFilterButton, FilterForm, year, filterFormHidden,
    handleFilterFormHidden, exportExtras, exportName,
  } = props;

  function exportCurrentTable() {
    const resp = dataSource.exportCurrentTable(undefined, exportExtras);
    resp.then((response) => {
      // check if the response is empty
      if (response.data.type === 'application/json') {
        notify({
          msg: locObject.translate('nodata_export'), type: 'error', desc: '', duration: 10,
        });
      } else {
        // use a virtual <a> element to trigger the navigator download Event
        const hiddenElement = document.createElement('a');
        const blob = new Blob([response.data]);
        hiddenElement.href = URL.createObjectURL(blob);
        // save the file with the current date
        const date = moment().format('YYYY-MM-SS HH-MM-SS');
        hiddenElement.download = `${exportName}-${date}.xlsx`;
        hiddenElement.click();
      }
    });
  }

  // States

  const [filterIsOn, setFilterIsOn] = useState(false);

  useEffect(() => {
    const storedFilterData = localStorage.getItem(activityName);
    if (storedFilterData !== '{}' && storedFilterData !== undefined && storedFilterData !== null) {
      setFilterIsOn(true);
    }
  }, []);

  // Hooks

  function handleFilterForm(filterValues) {
    handleSetFilteredData(filterValues);
    handleFilterFormHidden(true);
  }

  function cancelModifications(modified) {
    setFilterIsOn(false);
    const resp = new Promise((resolve, reject) => {
      if (modified === true) {
        localStorage.setItem(activityName, JSON.stringify({ year }));
        handleSetFilteredData(null);
      }
      handleFilterFormHidden(true);
      resolve();
    });
    return resp;
  }
  function removeFilters() {
    const deleted = cancelModifications(true);
    deleted.then(() => {
      form.resetFields();
    });
  }
  function handleShowForm() {
    handleFilterFormHidden(false);
  }

  return (
    <div style={{ marginBottom: '10px' }}>
      <Card className="d-100" size="small" bordered={false} hidden={filterFormHidden.isButtonHidden}>
        <Row>
          <Col flex="auto">
            <Row justify="end" gutter={[10, 2]}>

              {showExportButton && (
              <Col>
                <Button
                  onClick={exportCurrentTable}
                  type="default"
                  shape="default"
                >
                  <DownloadOutlined />
                  {locObject.translate('export')}
                </Button>

              </Col>
              )}
              {showFilterButton && (

              <Col>
                {filterIsOn === true ? (
                  <Button
                    danger
                    onClick={removeFilters}
                  >
                    {locObject.translate('removeFiltersButtonText')}
                  </Button>

                ) : (
                  <Button
                    onClick={handleShowForm}
                    className={filterIsOn ? styles.filterButtonClicked : styles.filterButton}
                    type="default"
                    shape="default"
                  >
                    <FilterFilled />
                    {locObject.translate('filterButtonText')}
                  </Button>

                )}
              </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Card>
      <Row style={{ fontSize: '2vw' }} gutter={[0, 20]} hidden={filterFormHidden.isFormHidden}>
        <Col span={24}>
          <Card>
            <div style={{ display: 'flex' }}>
              <Row className="d-50">
                <Title level={5}>{title}</Title>
              </Row>
              <Row justify="end" className="d-50">
                <Col
                  span={1}
                  style={{ fontSize: '2em', color: 'var(--primary-color)' }}
                  onClick={() => cancelModifications(false)}
                >
                  <CloseCircleFilled />
                </Col>
              </Row>
            </div>
            {FilterForm && (
            <FilterForm
              setFilterIsOn={setFilterIsOn}
              onSubmitData={handleFilterForm}
              onCancelModifications={cancelModifications}
              user={user}
              dataSource={dataSource}
              form={form}
            />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
TableAddonsBox.defaultProps = {
  showExportButton: true,
  showFilterButton: true,
};

TableAddonsBox.propTypes = {
  showExportButton: PropTypes.bool,
  showFilterButton: PropTypes.bool,

};
export default TableAddonsBox;
